<template>
  <div>
    <!--begin::User-->
    <h3 class="card-label">
      {{ $t('document_type_setting.document_type_setting') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>
    <div class="row">
      <div class="col-lg-6">
        <div class="card card-custom">
          <div class="card-body row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('document_type_setting.document_type') }}<span class="text-danger">*</span></label>
              <select name="document_type" id="document_type" v-model="data.doc_type" @input="selectDocType" class="custom-select" :class="validation && validation.doc_type ? 'is-invalid' : ''">
                <option v-for="row in document_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.doc_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.doc_type[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('document_type_setting.document_slug') }}<span class="text-danger">*</span></label>
              <input :value="data.slug" disabled class="form-control" :class="validation && validation.slug ? 'is-invalid' : ''">
              <span v-if="validation && validation.slug" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.slug[0] }}
                            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card card-custom">
          <div class="card-body row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('document_type_setting.document_name') }}<span class="text-danger">*</span></label>
              <input v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('document_type_setting.status_accounting') }}<span class="text-danger">*</span></label>
              <select name="client_layout" id="client_layout" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
              <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
            </div>
            <!--              <div class="col-lg-12 mb-5">-->
            <!--                <label>{{ $t('document_type_setting.columns') }}<span class="text-danger">*</span></label>-->
            <!--                <multiselect v-model="data.columns_doc"-->
            <!--                             :placeholder="$t('document_type_setting.columns')"-->
            <!--                             :options="columns_doc_list"-->
            <!--                             :multiple="true"-->
            <!--                             :taggable="true"-->
            <!--                             :class="validation && validation.columns_doc ? 'is-invalid' : ''"-->
            <!--                             :show-labels="false"-->
            <!--                             :show-no-options="false"-->
            <!--                             :show-no-results="false">-->
            <!--                </multiselect>-->
            <!--                <span v-if="validation && validation.columns_doc" class="fv-plugins-message-container invalid-feedback">-->
            <!--                                {{ validation.columns_doc[0] }}-->
            <!--                            </span>-->
            <!--              </div>-->
            <div class="col-lg-12 mb-5">
              <label>{{ $t('document_type_setting.related_tables') }}<span class="text-danger">*</span></label>
              <multiselect v-model="data.related_tables"
                           :placeholder="$t('document_type_setting.related_tables')"
                           :options="related_tables_list"
                           :multiple="true"
                           :taggable="true"
                           :class="validation && validation.related_tables ? 'is-invalid' : ''"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
              <span v-if="validation && validation.related_tables" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.related_tables[0] }}
                            </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-12">
        <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
          <h6 class="mt-2">{{ $t('document_type_setting.description_columns') }}</h6>
          <div class="d-flex">
            <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>
          </div>
        </div>
        <div class="table-responsive bg-white pb-5">
          <table class="table">
            <thead>
            <tr>
              <th width="20%">{{ $t('document_type_setting.table') }}</th>
              <th width="20%">{{ $t('document_type_setting.column') }}</th>
              <th width="20%">{{ $t('document_type_setting.account_routing') }}</th>
              <th width="20%">{{ $t('document_type_setting.is_predefined') }}</th>
              <th width="20%">{{ $t('document_type_setting.is_active') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in columns" :key="'columns_'+index">
              <td>
                <b-form-select v-model="row.table_slug" :options="related_tables_list" @input="selectTable(index)" value-field="column_slug" text-field="column_name"></b-form-select>
                <span v-if="validation && validation.doc_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.doc_type[0] }}
                            </span>
                <!--                <multiselect v-model="row.table_slug"-->
                <!--                             :placeholder="$t('document_type_setting.table')"-->
                <!--                             :options="related_tables_list"-->
                <!--                             :multiple="false"-->
                <!--                             :taggable="false"-->
                <!--                             @input="selectTable(index)"-->
                <!--                             :class="validation && validation.table_name ? 'is-invalid' : ''"-->
                <!--                             :show-labels="false"-->
                <!--                             :show-no-options="false"-->
                <!--                             :show-no-results="false">-->
                <!--                </multiselect>-->
              </td>
              <td>
                <b-form-select v-model="row.column_slug" :options="row.column_list" @input="selectColumn(index)" value-field="column_slug" text-field="column_name"></b-form-select>
                <!--                <multiselect v-model="row.column"-->
                <!--                             :placeholder="$t('document_type_setting.column')"-->
                <!--                             :options="row.column_list"-->
                <!--                             label="column_name"-->
                <!--                             id="column_slug"-->
                <!--                             :multiple="false"-->
                <!--                             :taggable="false"-->
                <!--                             :class="validation && validation.column_name ? 'is-invalid' : ''"-->
                <!--                             :show-labels="false"-->
                <!--                             :show-no-options="false"-->
                <!--                             :show-no-results="false">-->
                <!--                </multiselect>-->
              </td>
              <td>
                <b-form-select v-model="row.key_id" :options="accounts_list" @input="selectKey(index)" value-field="id" text-field="name"></b-form-select>

                <!--                <treeselect-->
                <!--                    :options="accounts_list"-->
                <!--                    :load-options="loadOptions"-->
                <!--                    :multiple="false"-->
                <!--                    :value="row.key_id"-->
                <!--                    @input="updateValueTree(index, $event)"-->
                <!--                    :searchable="true"-->
                <!--                    :class="validation && validation.key_id ? 'is-invalid' : ''"-->
                <!--                    :show-count="true"-->
                <!--                    :no-children-text="$t('No_sub_options')"-->
                <!--                    :no-options-text="$t('No_options_available')"-->
                <!--                    :no-results-text="$t('No_results_found')"-->
                <!--                    :placeholder="$t('Select')">-->
                <!--                </treeselect>-->
              </td>
              <td>
                <b-form-checkbox size="lg" v-model="row.is_predefined" name="check-button" switch></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
              </td>
              <td>
                <v-icon style="color: #dc3545;" small @click="removeItemRowFromList(index)">mdi-delete</v-icon>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--      <b-card no-body class="mt-5">-->
    <!--        <b-card-header header-tab="header" class="p-1" role="tab">-->
    <!--          <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">-->
    <!--            {{ $t('document_type_setting.description_columns')}}-->
    <!--          </b-button>-->
    <!--        </b-card-header>-->
    <!--        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">-->
    <!--          <b-card-body class="row">-->
    <!--              <table class="table">-->
    <!--                <thead>-->
    <!--                <tr>-->
    <!--                  <th>{{ $t('document_type_setting.column') }}</th>-->
    <!--                  <th>{{ $t('document_type_setting.description_ar') }}</th>-->
    <!--                  <th>{{ $t('document_type_setting.description_en') }}</th>-->
    <!--                </tr>-->
    <!--                </thead>-->
    <!--                <tbody>-->
    <!--                <tr v-for="(row, index) in details_list" :key="'details_'+index">-->
    <!--                  <td>{{ row.key }}</td>-->
    <!--                  <td>{{ row.description_ar }}</td>-->
    <!--                  <td>{{ row.description_en }}</td>-->
    <!--                </tr>-->
    <!--                </tbody>-->
    <!--              </table>-->
    <!--          </b-card-body>-->
    <!--        </b-collapse>-->
    <!--      </b-card>-->
    <div class="pl-0 pr-0">
      <div class="row">
        <div class="col-lg-6 mt-10">
          <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
        </div>
      </div>
    </div>


    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-document-type-setting",
  data() {
    return {
      mainRoute: 'accounting/document_type_settings',
      mainRouteDependency: 'base/dependency',
      mainRouteByDocType: 'base/dependency/data_doc',
      documentsMainRoute: '/accounting/documents',
      routingConfigurationsMainRoute: '/accounting/routing-configurations',

      document_type_list: [],
      status_list: [],
      columns_doc_list: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,

      data: {
        doc_type: null,
        name: null,
        slug: null,
        status: null,
        // columns_doc: null,
        related_tables: null,
      },
      isEditing: false,
      validation: null,
      details_list: [],
      accounting_documents_list: [],
      related_tables_list: [],
      columns_repeater: {
        table_name: null,
        table_slug: null,
        column_name: null,
        column_slug: null,
        key_id: null,
        key_slug: null,
        is_predefined: false,
        is_active: true,
        column_list: [],
      },
      columns: [],
      accounts_list: [],

    };
  },

  watch: {

    'data.slug': function (val) {
      this.getAccountingList(val)
    },
    // 'data.doc_type': function (val) {
    //   if (val) {
    //     let _doc = this.document_type_list.find((row) => row.id == val);
    //     if (_doc) {
    //       this.data.slug = _doc.slug;
    //     }
    //     let promise = this.getDataByDocType();
    //     Promise.all([promise]).then(() => {
    //       this.getStatusList(val);
    //       // this.getColumnsDocList(val);
    //       // this.getDetailsListColumnsDoc(val);
    //
    //
    //     });
    //   } else {
    //     this.status_list = [];
    //     this.columns_doc_list = [];
    //   }
    // }
  },
  methods: {
    selectDocType: _.debounce(function () {
      let val = this.data.doc_type ? this.data.doc_type : null;
      if (val) {
        let _doc = this.document_type_list.find((row) => row.id == val);
        if (_doc) {
          this.data.slug = _doc.slug;
        }

        /**
         * get related tables
         * @type {*[]}
         */
        this.data.related_tables = [];
        this.related_tables_list = [];
        this.accounting_documents_list.forEach((row) => {
          row.related_table.forEach((_row) => {
            this.related_tables_list.push(_row.table_name)
          });
        });
        this.data.related_tables = [...this.related_tables_list];


        this.getStatusList(val);
        // let promise = this.getDataByDocType();
        // Promise.all([promise]).then(() => {
        //   this.getStatusList(val);
        //   // this.getColumnsDocList(val);
        //   // this.getDetailsListColumnsDoc(val);
        //
        //
        // });
      } else {
        this.status_list = [];
        this.columns_doc_list = [];
      }

    }, 100),
    addItemRowToList() {
      this.columns.unshift(this.columns_repeater);
      this.columns_repeater = {
        table_name: null,
        table_slug: null,
        column_name: null,
        column_slug: null,
        key_id: null,
        key_slug: null,
        is_predefined: false,
        is_active: true,
        column_list: [],
      };

    },
    getUniqueKeyValue() {
      return Math.floor(Math.random() * 1000000000);
    },
    removeItemRowFromList(index = 0) {
      if (this.columns.length > 0) {
        this.columns.splice(index, 1);
      }
    },

    // loadOptions() {
    // },
    //
    // updateValueTree(index, value) {
    //   this.columns[index].key_slug = value;
    // },

    selectTable(index) {
      // this.getAccountingList(this.columns[index].table_slug);
      this.columns[index].column_list = [];
      this.accounting_documents_list.forEach((row) => {
        row.items.forEach((_row) => {
          if (this.columns[index].table_slug == _row.table_slug) {
            this.columns[index].table_name = _row.table_name;

            this.columns[index].column_list.push({
              column_name: _row.column_name,
              column_slug: _row.column_slug,
            });
          }
        })
      })

    },
    selectColumn(index) {
      let _column = this.columns[index].column_list.find((row) => row.column_slug == this.columns[index].column_slug);
      if (_column) {
        this.columns[index].column_name = _column.column_name;
      }
    },
    selectKey(index) {
      let _key = this.accounts_list.find((row) => row.id == this.columns[index].key_id);
      if (_key) {
        this.columns[index].key_slug = _key.key;
      }
    },
    customColumnsForRequest() {
      let _columns = this.columns.map((row) => {
        return {
          table_name: row.table_name,
          table_slug: row.table_slug,
          column_name: row.column_name,
          column_slug: row.column_slug,
          key_id: row.key_id,
          key_slug: row.key_slug,
          is_predefined: row.is_predefined,
          is_active: row.is_active,
        }
      });
      return _columns;
    },
    setDataOfColumns(data) {
      this.columns = data.columns.map((col_row) => {
        col_row.column_list = [];
        this.accounting_documents_list.forEach((row) => {
          row.items.forEach((_row) => {
            if (col_row.table_slug == _row.table_slug) {
              col_row.column_list.push({
                column_name: _row.column_name,
                column_slug: _row.column_slug,
              });
            }
          })
        })
        return col_row;
      });
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      let _columns = this.customColumnsForRequest();
      ApiService.post(this.mainRoute, {
        ...this.data,
        columns: _columns,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/document-type-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      let _columns = this.customColumnsForRequest();
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        columns: _columns,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/document-type-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.doc_type = response.data.data.data.doc_type;
        this.data.name = response.data.data.data.name;
        this.data.slug = response.data.data.data.slug;
        this.data.status = response.data.data.data.status;
        // this.data.columns_doc = response.data.data.columns_doc;
        this.data.related_tables = response.data.data.data.related_tables;
        this.related_tables_list = response.data.data.data.related_tables;
        this.setDataOfColumns(response.data.data.data);
        this.getStatusList(response.data.data.data.doc_type);

      });
    },
    async getDataByDocType() {
      this.validation = null;
      await ApiService.get(this.mainRouteByDocType + '/' + this.data.doc_type).then((response) => {
        if (response.data.data) {
          this.isEditing = true;
          this.idEdit = response.data.data.data.id;
          this.data.doc_type = response.data.data.data.doc_type;
          this.data.name = response.data.data.data.name;
          this.data.slug = response.data.data.data.slug;
          this.data.status = response.data.data.data.status;
          // this.data.columns_doc = response.data.data.columns_doc;
          this.data.related_tables = response.data.data.data.related_tables;
          this.related_tables_list = response.data.data.data.related_tables;
          this.setDataOfColumns(response.data.data.data);
          this.getStatusList(response.data.data.data.doc_type);

        } else {
          this.isEditing = false;
          this.idEdit = null;
          this.data.name = null;
          //this.data.slug = null;
          this.data.status = null;
          this.data.columns_doc = null;
          this.columns = [];
        }
      });
    },

    // getDocumentTypeList() {
    //   ApiService.get(this.mainRouteDependency + "/doc_type_list").then((response) => {
    //     this.document_type_list = response.data.data;
    //   });
    // },

    getStatusList(id) {

      ApiService.get(this.mainRouteDependency + "/status_by_doc_type/" + id).then((response) => {
        this.status_list = response.data.data;
      });
    },

    getColumnsDocList(id) {
      ApiService.get(this.mainRouteDependency + "/columns_doc/" + id).then((response) => {
        this.columns_doc_list = response.data.data;
      });
    },

    // getDetailsListColumnsDoc(id) {
    //   ApiService.get(this.mainRouteDependency + "/details_columns_doc/" + id).then((response) => {
    //     this.details_list = response.data.data;
    //   });
    // },
    getAccountingDocumentsList() {
      ApiService.get(this.documentsMainRoute).then((response) => {
        this.accounting_documents_list = response.data.data;
        this.document_type_list = response.data.data.map((row) => {
          return {
            id: row.id,
            slug: row.doc_slug,
            title: row.doc_name,
          }
        });


      });
    },
    getAccountingList(type) {
      ApiService.get(`${this.routingConfigurationsMainRoute}?doc_type=${type}`).then((response) => {
        this.accounts_list = response.data.data;

      });
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.document_type_setting"), route: '/accounting/document-type-setting'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    // this.getDocumentTypeList();
    this.getAccountingDocumentsList();
    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


